import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { formatDistanceToNow, isValid, parseISO, parse as parseDate } from 'date-fns';
import { dateRangeLabels, defaultLocations, urls } from 'app-constants';
import { useUserContext } from 'context';
import { useToggle } from 'hooks';
import { formatDateRange, getCsrfToken } from 'utils';
import { DISTANCE_UNITS_LABELS } from 'components/filters';
import TruncateText from 'components/TruncateText';
import CardDeletionMenu from 'components/CardDeletionMenu';
import SavedSearchTextExport from 'components/SavedSearchTextExport';
import Icon from 'components/Icon';
import LoadingBubbles from 'components/LoadingBubbles';

const SavedSearchCard = ({
  className,
  useCompactStyle,
  id,
  searchName,
  searchUrl,
  defaultLocId,
  address,
  radius,
  namedDate,
  dateStart,
  dateEnd,
  keyword,
  eventSetting,
  eventTypeLabels,
  ageGroupLabels,
  priceLabels,
  created,
  updated,
  metaLabel = 'updated',
  onDeleteSuccess = () => {},
  onDeleteFailure = () => {},
  onRenameSuccess = () => {},
  onRenameFailure = () => {},
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { distanceUnits, isEditor, isStaff } = useUserContext();
  const distanceUnitsLabel = DISTANCE_UNITS_LABELS[distanceUnits];

  const handleCardClick = () => window.location.href = searchUrl;
  const handleTitleClick = evt => evt.stopPropagation(); // Don't break cmd+click

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [titleValue, setTitleValue] = useState(searchName);
  useEffect(() => setTitleValue(searchName), [searchName]);

  const dateCreated = parseISO(created);
  const dateUpdated = parseISO(updated);

  const handleEditClick = evt => {
    evt.stopPropagation();
    setIsEditing(true);
  };

  const handleTitleFormSubmit = evt => {
    evt && evt.preventDefault();
    setIsSaving(true);

    fetch(`${urls.savedSearchBase}${id}/`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'X-CSRFToken': getCsrfToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ search_name: titleValue }),
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        setIsSaving(false);
        setIsEditing(false);
        onRenameSuccess(titleValue);
      })
      .catch(err => {
        console.error(err);
        setIsSaving(false);
        setIsEditing(false);
        // revert to original value
        setTitleValue(searchName);
        onRenameFailure(err);
      });
  };

  const handleSaveClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    handleTitleFormSubmit();
  };

  const handleCancelClick = evt => {
    evt.preventDefault();
    if (isSaving) return null;
    setTitleValue(searchName);
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setIsDeleting(true);

    fetch(`${urls.savedSearchBase}${id}/`, {
      credentials: 'include',
      method: 'DELETE',
      headers: { 'X-CSRFToken': getCsrfToken() },
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        onDeleteSuccess();
      })
      .catch(err => {
        console.error(err);
        setIsDeleting(false);
        onDeleteFailure(err);
      });
  };

  let dateText;
  if (namedDate) {
    dateText = dateRangeLabels[namedDate.replaceAll('_', '-')];
  } else if (dateStart && dateEnd) {
    const dateStartAsDate = parseDate(dateStart, 'yyyy-MM-dd', new Date());
    const dateEndAsDate = parseDate(dateEnd, 'yyyy-MM-dd', new Date());
    if (isValid(dateStartAsDate) && isValid(dateEndAsDate)) {
      dateText = formatDateRange(dateStartAsDate, dateEndAsDate);
    }
  }

  const defaultLoc = defaultLocations.find(({ id }) => id === defaultLocId);
  let locName;
  if (address) {
    const { addressName, lat, lon, streetNumber, street, city, state } = address;
    if (addressName) {
      locName = addressName;
    } else if (street || city || state) {
      const parts = [[streetNumber, street].join(' '), city, state];
      locName = parts.join(', ');
    } else if (lat && lon) {
      locName = `${lat}, ${lon}`;
    }
  } else if (defaultLoc) {
    locName = defaultLoc.name;
  } else if (defaultLocId === 'current') {
    locName = 'Current Location';
  }

  const eventSettingLabel = {
    online: 'Online',
    in_person: 'In Person',
  }[eventSetting];

  const showFilters = !!eventSettingLabel || eventTypeLabels.length > 0 || ageGroupLabels.length > 0 || priceLabels.length > 0;

  const deletionMenuExtraItems = !useCompactStyle ? [] : [
    { label: 'Rename', onClick: handleEditClick },
  ];

  const [showExportModal, toggleShowExportModal] = useToggle(false);
  deletionMenuExtraItems.push({
    label: 'Export Results',
    onClick: () => toggleShowExportModal(),
  });

  if (isStaff) {
    deletionMenuExtraItems.push({
      label: 'Preview Email',
      attrs: {
        href: `${urls.savedSearchMailPreviewBase}${id}/`,
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    });

    const sendTestMail = () => {
      fetch(urls.savedSearchMailSendTest, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'X-CSRFToken': getCsrfToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      })
        .then(response => {
          if (!response.ok) throw new Error(response.statusText);
          return response;
        })
        .catch(err => {
          console.error(err);
        });
    };
    deletionMenuExtraItems.push({
      label: 'Send Email',
      onClick: sendTestMail,
    });
  }

  return (
    <div
      className={classNames('listings-card', 'saved-search-card', className)}
      style={{ opacity: isDeleting ? 0.5 : 1 }}
      onClick={handleCardClick}
    >
      <section className="lc-top">
        <div className={classNames('lc-main p-0')}>
          <div>
            <div className="d-flex align-items-start">
              {isEditing ? (
                <div
                  className="saved-search-card-title-form-wrap"
                  onClick={evt => evt.stopPropagation()}
                >
                  <form className="saved-search-card-title-form" onSubmit={handleTitleFormSubmit}>
                    <input
                      autoFocus
                      maxLength={65}
                      className="saved-search-card-title-input"
                      type="text"
                      value={titleValue}
                      onChange={evt => setTitleValue(evt.target.value)}
                    />
                  </form>

                  <div className="saved-search-card-save-link">
                    {isSaving
                      ? <LoadingBubbles />
                      : <a href="#save" className="fw-bold" onClick={handleSaveClick}>Save</a>}
                  </div>

                  <div className="ms-3">
                    <a
                      className="reset-link fw-bold"
                      style={{ opacity: isSaving ? 0.5 : 1 }}
                      href="#cancel"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center mb-2">
                  <h5 className="saved-search-card-title m-0">
                    <a href={searchUrl} onClick={handleTitleClick}>
                      <TruncateText text={titleValue} numChars={65} />
                    </a>
                  </h5>

                  {!useCompactStyle && (
                    <div className="saved-search-card-edit-button ms-2" onClick={handleEditClick}>
                      <Icon i={['fal', 'edit']} size="sm" />
                    </div>
                  )}
                </div>
              )}
            </div>

            {!!locName && (
              <div className="mb-2 lh-sm d-flex align-items-center">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i="map-marker-alt" size="sm" />
                </div>
                {locName} {!!radius && !!distanceUnitsLabel && `within ${radius} ${distanceUnitsLabel}`}
              </div>
            )}

            {!!dateText && (
              <div className="mb-2 lh-sm d-flex align-items-center">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i={['far', 'calendar']} size="sm" />
                </div>
                {dateText}
              </div>
            )}

            {!!keyword && (
              <div className="mb-2 lh-sm d-flex align-items-center">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i="search" size="sm" />
                </div>
                {keyword}
              </div>
            )}

            {showFilters && (
              <div className="mb-2 lh-sm d-flex">
                <div className="me-2 d-flex justify-content-center" style={{ flex: '0 0 20px', color: '#cacaca' }}>
                  <Icon i={['faz', 'filter']} />
                </div>
                <div>
                  {eventTypeLabels.length > 0 && (
                    <div className="saved-search-card-filter-line">
                      <strong>Category</strong> &nbsp;
                      <span dangerouslySetInnerHTML={{ __html: eventTypeLabels.join('&nbsp;&nbsp;•&nbsp; ') }} />
                    </div>
                  )}
                  {ageGroupLabels.length > 0 && (
                    <div className="saved-search-card-filter-line">
                      <strong>Age Group</strong> &nbsp;
                      <span dangerouslySetInnerHTML={{ __html: ageGroupLabels.join('&nbsp;&nbsp;•&nbsp; ') }} />
                    </div>
                  )}
                  {priceLabels.length > 0 && (
                    <div className="saved-search-card-filter-line">
                      <strong>Price</strong> &nbsp;
                      <span dangerouslySetInnerHTML={{ __html: priceLabels.join('&nbsp;&nbsp;•&nbsp; ') }} />
                    </div>
                  )}
                  {!!eventSettingLabel && (
                    <div className="saved-search-card-filter-line">
                      <strong>Type</strong> &nbsp;
                      <span>{eventSettingLabel}</span>
                    </div>
                  )}
                </div>
              </div>
            )}

          </div>
        </div>

        <div className="lc-right">
          <div onClick={e => e.stopPropagation()}>
            <CardDeletionMenu
              onDeleteClick={handleDeleteClick}
              extraItems={deletionMenuExtraItems}
              deleteLinkAttrs={{
                'data-search-name': `${id} - ${searchName}`,
                'data-action': 'delete',
              }}
            />
            <SavedSearchTextExport
              searchId={id}
              isOpen={showExportModal}
              onRequestClose={toggleShowExportModal}
            />
          </div>
        </div>
      </section>

      {!useCompactStyle && (
        <div className="saved-search-card-meta">
          {metaLabel === 'created' && `Created ${formatDistanceToNow(dateCreated)} ago`}
          {metaLabel === 'updated' && `Edited  ${formatDistanceToNow(dateUpdated)} ago`}
        </div>
      )}
    </div>
  );
};

SavedSearchCard.propTypes = {
  className: PropTypes.string,
  useCompactStyle: PropTypes.bool,
  id: PropTypes.number,
  searchName: PropTypes.string,
  searchUrl: PropTypes.string,
  defaultLocId: PropTypes.oneOf(['', 'current', ...defaultLocations.map(({ id }) => id)]),
  address: PropTypes.shape({
    id: PropTypes.number,
    addressName: PropTypes.string,
    lat: PropTypes.number,
    lon: PropTypes.number,
    streetNumber: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  radius: PropTypes.number,
  namedDate: PropTypes.oneOf(['', 'today', 'tomorrow', 'this_weekend', 'next_3', 'next_7', 'next_30']),
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  keyword: PropTypes.string,
  eventSetting: PropTypes.oneOf(['', 'online', 'in_person']),
  eventTypeLabels: PropTypes.arrayOf(PropTypes.string),
  ageGroupLabels: PropTypes.arrayOf(PropTypes.string),
  priceLabels: PropTypes.arrayOf(PropTypes.string),
  created: PropTypes.string,
  updated: PropTypes.string,
  metaLabel: PropTypes.oneOf(['updated', 'created']),
  onDeleteSuccess: PropTypes.func,
  onDeleteFailure: PropTypes.func,
  onRenameSuccess: PropTypes.func,
  onRenameFailure: PropTypes.func,
};

export default SavedSearchCard;
